import Container from "@mui/material/Container";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import FadeIn from "react-fade-in";
import config from "../config.json";
import axios from "axios";
import parse from "html-react-parser";

export const ServerNews = () => {

    const apiEndPoint = config.apiEndPoint + 'info/server-news'
    const [news, setNews] = useState();
    useEffect(() => {
        axios.get(apiEndPoint)
            .then((response) => response.data)
            .then(data => {
                setNews(data);
                console.log(data);
                console.log(data);
            });
    }, [])
    return (
        <Box>
            <Container style={{textAlign: "center", marginTop: 50, paddingBottom: 50}}>
                <Divider style={{
                    width: '30%',
                    margin: '0 auto',
                    marginTop: 20,
                    height: 5
                }
                }/>

                {(!news || news.length === 0) && (<h5>UPDATES</h5>)}
                {(news && news?.length > 0) && (

                    <FadeIn>
                        <ul className="timeline">
                            {news?.map((e) => (
                                <li className="timeline-item">
                                    <Box className="timeline-panel selected_past">
                                        <Box className="timeline-arrow"></Box>
                                        <Box className="timeline-heading">
                                            <h2 style={{color: '#1a2531'}} className="title is-size-6">{e?.title}</h2>
                                            <Box className="timeline-panel-controls">
                                                <Box
                                                    className="timestamp"><span>{new Date(e?.date).toLocaleString()}</span></Box>
                                            </Box>
                                        </Box>
                                        <Box className="timeline-body">
                                            {parse(e?.content)}
                                        </Box>
                                    </Box>
                                </li>
                            ))}
                        </ul>
                    </FadeIn>
                )}


            </Container>
        </Box>
    );
}