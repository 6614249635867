import './App.css'
import './fonts.css'
import AuthService from './services/authService'
import {Navigate, Route, Routes} from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'
import LoginPage from './components/pages/login-page'
import Logout from './components/pages/logout'
import RegisterPage from './components/pages/register-page'
import ForgotPassword from './components/pages/forgot-password'
import ChangePassForm from './components/pages/change-pass'
import UserProfile from './components/pages/profile'
import Home from './components/pages/home'
import Downloads from './components/pages/downloads'
import RankingsPage from './components/pages/ranks'
import Footer from './components/common/footer'
import React, {useCallback, useEffect, useState} from 'react'
import ResponsiveAppBar from "./components/common/new-nav-bar";
import Box from "@mui/material/Box";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useInterceptor} from "./services/axios-interceptors";
import {MarketPlacePage} from "./components/pages/marketplace";
import {PlayerProfile} from "./components/pages/player-profile";
import {FinderPage} from "./components/pages/finder-page";
import ResetPassword from "./components/pages/reset-password";
import axios from "axios";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import BgImage from './assets/bg.jpg'
import config from './config.json'
import EventsPage from "./components/pages/events-page";
import {ServerNewsPage} from "./components/pages/server-news-page";

const App = () => {

    useInterceptor();
    const ProtectedRouteLoggedIn = ({children}) => {
        if (!AuthService.isUserLoggedIn()) {
            return <Navigate to="/user/login" replace/>;
        }
        return children;
    };
    const ProtectedRouteNotLogged = ({children}) => {
        if (AuthService.isUserLoggedIn()) {
            return <Navigate to="/" replace/>;
        }
        return children;
    };

    return (
        <div className="App">
            <ToastContainer/>
            <Box sx={{
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                position: 'fixed',
                width: '100%',
                height: "100%",
                background: `url('${BgImage}')`
            }}/>

            <Box style={{position: 'relative'}}>
                <ResponsiveAppBar/>
                <Box className="main">
                    <Routes>
                        <Route path="/ranks" element={<RankingsPage/>}></Route>
                        <Route path="/downloads" element={<Downloads/>}></Route>
                        <Route path="/updates" element={<ServerNewsPage/>}></Route>
                        <Route path="/user/login" element={
                            <ProtectedRouteNotLogged>
                                <LoginPage/>
                            </ProtectedRouteNotLogged>
                        }></Route>
                        <Route path="/user/logout" element={<Logout/>}></Route>
                        <Route path="/user/profile" element={<UserProfile/>}></Route>
                        <Route path="/user/change-password"
                               element={
                                   <ProtectedRouteLoggedIn>
                                       <ChangePassForm/>
                                   </ProtectedRouteLoggedIn>
                               }>
                        </Route>
                        <Route path="/recoverpassword" element={<ResetPassword/>}></Route>
                        <Route path="/user/register" element={<RegisterPage/>}></Route>
                        <Route path="/finder" element={<FinderPage/>}></Route>
                        <Route path="/marketplace" element={<MarketPlacePage/>}></Route>
                        <Route path="/user/forgot-password" element={<ForgotPassword/>}></Route>
                        <Route path="/schedules" element={<EventsPage/>}></Route>
                        <Route path="/player-profile" path=":name" element={<PlayerProfile/>}></Route>
                        <Route path="/" exact element={<Home/>}></Route>
                    </Routes>
                </Box>
            </Box>
            <Footer/>

        </div>
    )
}
export default App;