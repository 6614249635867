import React from 'react'
import {Box, Divider, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";

const Footer = (props) => {
    return (
        <footer className="s-footer" style={{position: 'relative'}}>
            <Box sx={{ py: 1, px: 10 }}>
               
                <Grid container>
                    <Grid item lg={4}>
                        <Grid item lg={6} md={6} xs={12}>
                            <iframe src="https://discord.com/widget?id=1293099847616036905&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
                        </Grid>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Conquer Job 2024</Typography>
                        <Typography>Purely Classic Conquer Online 1.0 features that we all love. The nostalgia feeling where a little hard
                            work goes a long way.The real mean of PK over DragonBall.</Typography>

                        

                    </Grid>
                   
                    <Grid item lg={4}>
                        
                    </Grid>
                  
                    <Grid item lg={4}>
                        <Grid item lg={6} xs={12}>
                            <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/conquerjob&tabs=timeline&width=400&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="400" height="500" style={{ border: "none", overflow: "hidden" }} scrolling="no"
                                frameBorder="0" allowFullScreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </Grid>
                        <Typography fontWeight={'bold'} sx={{mb:3}}>Disclaimer</Typography>

                        <Typography>Conquer Job is NOT related on TQ Digital or any Server.</Typography>

                        <div className="col-md-push-2 col-md-3 mt-2">
                            <div className="footer-col">
                                <h4 className="widget-title">Links</h4>

                                <ul className="list-inline social-1">
                                   
                                   
                                    <li><a href="https://discord.gg/QfKVrf4AhT">Discord</a></li>
                                    <li><a href="https://www.facebook.com/conquerjob">Facebook</a> </li>


                                </ul>
                            </div>
                        </div>
                       

                    </Grid>
                </Grid>
            </Box>
        </footer>
    )
}

export default Footer
