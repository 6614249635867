import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AuthService from "../../services/authService";
import {useNavigate} from "react-router-dom";
import logo from '../../assets/logo.png';

const pages = ['Home', 'Register', 'Downloads', 'Ranks', 'Market', 'Updates', 'Schedule', 'Store'];
const pagesPath = ['/', '/user/register', '/downloads', '/ranks', '/marketplace', '/updates', '/schedules', 'https://conquerjobshop.weebly.com/'];
const settingsLoggedIn = ['Change Password', 'Logout'];
const loggedInPages = ['/user/change-password', '/user/logout'];


const settingsLoggedOut = ['Login', 'Forgot password'];
const loggedOutPages = ['/user/login', '/user/forgot-password'];

function ResponsiveAppBar() {

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const history = useNavigate();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = (page) => {
        setAnchorElNav(null);
        if (page)
            doNav(page);


    };
    const doNav = (page) => {
        if (page) {
            const index = pages.indexOf(page);
            if (index !== -1) {
                const url = pagesPath[index]
                if (url?.includes('https://')) {
                    window.location.href = url;
                } else {
                    history(url);
                }
            }
            const index2 = settingsLoggedIn.indexOf(page);
            if (index2 !== -1) {
                history(loggedInPages[index2]);
            }
            const index3 = settingsLoggedOut.indexOf(page);
            if (index3 !== -1) {
                history(loggedOutPages[index3]);
            }
        }
    }
    const handleCloseUserMenu = (page) => {
        setAnchorElUser(null);
        if (page)
            doNav(page);
    };

    return (
        <AppBar position="static" style={{
            backgroundColor: "rgba(0, 0, 0, 0.8)",
        }}>

            <Container maxWidth="xl" style={{
                padding: '20px'
            }}>

                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="white"
                        >
                            <MenuIcon style={{color: 'white'}}/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'},
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={() => handleCloseNavMenu(page)}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'} width={'100%'} alignItems={'center'}>
                        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                            <img style={{
                                borderRadius: '5px',
                                width: '80px',
                                marginRight: '20px',
                            }} src={logo} alt="logo"/>
                        </Box>

                        <Box sx={{flexGrow: 1, justifyContent: 'center', display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={() => handleCloseNavMenu(page)}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                    style={{
                                        fontSize: 14,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="User options">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar src=""/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {AuthService.isUserLoggedIn() && settingsLoggedIn.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                                {!AuthService.isUserLoggedIn() && settingsLoggedOut.map((setting) => (
                                    <MenuItem key={setting} onClick={() => handleCloseUserMenu(setting)}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default ResponsiveAppBar;