export const GameVideo = () => {
  return (
    <>
      <iframe
        style={{
          maxWidth: "100%",
          width: "100%",
          borderRadius: "20px",
        }}
        height="500"
        src="q;autoplay=1&mute=1"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </>
  );
};
